import {
  CatalogKindExploreContent,
  ExploreLayout,
  ToolExplorerContent,
  DomainExplorerContent,
  GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
// import { MyawsPage } from '@internal/plugin-myaws';

export const ExplorePage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';
  return (
    <ExploreLayout
      title={`Explore the ${organizationName} ecosystem`}
      subtitle="Discover solutions available in your ecosystem"
    >
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="system" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
      {/* <ExploreLayout.Route path="myaws" title="My Aws">
        <MyawsPage />
      </ExploreLayout.Route> */}
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
