import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

const techRadar: TechRadarLoaderResponse = {
  quadrants: [
    { id: 'Techniques', name: 'Techniques' },
    { id: 'Platforms', name: 'Platforms' },
    { id: 'Languages & Frameworks', name: 'Languages & Frameworks' },
    { id: 'Tools', name: 'Tools' },
  ],
  rings: [
    { id: 'adopt', name: 'ADOPT', color: '#93c47d' },
    { id: 'trial', name: 'TRIAL', color: '#fbdb84' },
    { id: 'assess', name: 'ASSESS', color: '#93d2c2' },
    { id: 'hold', name: 'HOLD', color: '#efafa9' },
  ],
  entries: [],
};

export class P7S1TechRadar implements TechRadarApi {
  downloadCsv = async () => {
    try {
      // load data from https://techradar.p7s1.io/csv/soeg-techs-2022-q1.csv (it's protected by login right now)
      const target = `/temp/soeg-techs-2024-q2.csv`; // file

      const res = await fetch(target, {
        method: 'get',
        headers: {
          'content-type': 'text/csv;charset=UTF-8',
          // 'Authorization': // in case you need authorisation
        },
      });

      if (res.status === 200) {
        const data = await res.text();
        return data
          .split('\n')
          .slice(1)
          .map(ar =>
            ar
              .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
              .map(refi =>
                // eslint-disable-next-line no-control-regex
                refi.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '').trim(),
              ),
          );
      }
      // eslint-disable-next-line no-console
      console.error(`Error code ${res.status}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    return null;
  };
  getData = async () => {
    // load data from https://techradar.p7s1.io/csv/soeg-techs-2022-q1.csv (it's protected by login right now)
    return await this.downloadCsv();
  };

  async load(): Promise<TechRadarLoaderResponse> {
    // if needed id prop can be used to fetch the correct data
    const data = techRadar;
    if (data.entries.length === 0) {
      // load data from https://techradar.p7s1.io/csv/soeg-techs-2022-q1.csv (it's protected by login right now)
      const request = await this.getData();
      request?.forEach(columns => {
        if (columns.length > 0) {
          const name = columns[0];
          const quadrant = columns[1];
          const ring = columns[2];
          // const isNew = columns[3]
          const description = columns[4];
          // const reasoning = columns[5]
          // const teams = columns[6]
          data.entries.push({
            key: name,
            id: name,
            title: name,
            quadrant: quadrant,
            timeline: [
              {
                moved: 0,
                ringId: ring,
                date: new Date('2022-01-01'),
                description: description,
              },
            ],
            links: [
              {
                url: '#',
                title: 'Learn more',
              },
            ],
          });
        }
      });
    }
    return Promise.resolve(data);
  }
}
